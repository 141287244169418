/**
 * SclCardCta api
 *
 */

// template
const template = `
	<div>
		<v-card flat class="scl-card-cta__card">
			<div class="scl-card-cta__title">
				<h5>Let's make it happen</h5>
			</div>
			<div class="scl-card-cta__cta">
				<a href="tel:13 15 52">
					<span>call us on</span>
					<h6>
						<v-icon>fas fa-phone-alt</v-icon>
						13 15 52
					</h6>
				</a>
			</div>
		</v-card>
	</div>
`;

// export template
export default template;
