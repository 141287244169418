/**
 * SclCard api
 *
 * overlayTextColour prop
 * @param {string} 'white--text'
 * @param {string} 'black--text'
 *
 * variant prop
 * @param {string} 'dark'
 * @param {string} 'light'
 */

// template
const template = `
	<scl-card-vertical inline-template :api="{ overlayTextColour: 'white--text', variant: 'dark' }" class="scl-component scl-card scl-card--vertical">
		<v-layout>
			<v-flex pa-4>
				<v-card :dark="propApi.variant === 'dark'" :light="propApi.variant === 'light'">
					<v-img :lazy-src="$root.dummyImage43" :src="$root.dummyImage43" aspect-ratio="1.33" :class="propApi.overlayTextColour"/>
					<v-card-title>
						<div>
							<h3 class="headline mb-3">{{$root.dummyWord}}</h3>
							<div>
								<p>{{$root.dummyParagraph}}</p>
							</div>
							<v-btn flat color="blue" href="#">{{$root.dummyWord}}</v-btn>
						</div>
					</v-card-title>
				</v-card>
			</v-flex>
		</v-layout>
	</scl-card-vertical>
`;

// export template
export default template;
